@font-face {
	font-family: 'MotoGPText';
	src: url(../fonts/MotoGPText-Regular.woff) format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'MotoGPText';
	src: url(../fonts/MotoGPText-Italic.woff) format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'MotoGPText';
	src: url(../fonts/MotoGPText-Light.woff) format('woff');
	font-weight: 200;
}

@font-face {
	font-family: 'MotoGPText';
	src: url(../fonts/MotoGPText-LightItalic.woff) format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'MotoGPText';
	src: url(../fonts/MotoGPText-Bold.woff) format('woff');
	font-weight: 600;
}

@font-face {
	font-family: 'MotoGPText';
	src: url(../fonts/MotoGPText-BoldItalic.woff) format('woff');
	font-weight: 600;
	font-style: italic;
}

/*@font-face {*/
/*	font-family: 'MotoGPText';*/
/*	src: url(../fonts/MotoGPDisplay-Bold.woff) format('woff');*/
/*	font-weight: 900;*/
/*}*/

/*@font-face {*/
/*	font-family: 'MotoGP Display';*/
/*	src: url(../fonts/MotoGPDisplay-Bold.woff) format('woff');*/
/*	font-weight: 900;*/
/*}*/

/* MGP Display */

@font-face {
	font-family: 'MGP Display';
	src: url(../fonts/MGP_display/MGPDisplay-Medium.woff2) format('woff2');
	font-weight: 500;
}

/* MGP Text */

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-Light.woff2) format('woff2');
	font-weight: 300;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-LightItalic.woff2) format('woff2');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-Regular.woff2) format('woff2');
	font-weight: 400;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-RegularItalic.woff2) format('woff2');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-Medium.woff2) format('woff2');
	font-weight: 500;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-MediumItalic.woff2) format('woff2');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-Bold.woff2) format('woff2');
	font-weight: 700;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-BoldItalic.woff2) format('woff2');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-Black.woff2) format('woff2');
	font-weight: 900;
}

@font-face {
	font-family: 'MGP Text';
	src: url(../fonts/MGP_text/MGPText-BlackItalic.woff2) format('woff2');
	font-weight: 900;
	font-style: italic;
}