/* autoprefixer grid: autoplace */

:root {
	--fontFamilyPrimary: "MGP Text", Arial, sans-serif;
	--fontFamilyAccent: "MGP Display", Arial, sans-serif;
	--fontFamilyThird: "Heebo", sans-serif;
	--primaryColor: #E3137F;
}
html, body {
	background: #EAEAEA;
	color: #000;
	font-family: var(--fontFamilyPrimary);
}

#root {
	font-family: var(--fontFamilyPrimary);
}

* {
	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
}

body.is-menu-open {
	overflow: hidden;
	position: fixed;
}

select::-ms-expand {
    display: none;
}

select {
	-ms-user-select: none;
	font-family: var(--fontFamilyPrimary);
}

textarea{
	-ms-overflow-style: none
}

input::-ms-clear {
    display: none;
}

.reactour__helper [data-tour-elem="controls"] {
	position: absolute;
	bottom: 75px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
}

.reactour__helper [data-tour-elem="controls"] .reactour__dot {
	background: #B9BABB;
	border-color: #B9BABB;
	width: 12px;
	height: 12px;
	margin-left: 15px;
	margin-right: 0;
}

.reactour__helper [data-tour-elem="controls"] .reactour__dot.reactour__dot--is-active {
	transform: scale(1);
	background: var(--primaryColor);
	border-radius: 24px;
	width: 38px;
}

.MuiFormControl-root,
.MuiFormControl-root .MuiInputLabel-root,
.MuiFormControl-root .MuiInputLabel-root,
.MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root .MuiOutlinedInput-notchedOutline legend span,
.MuiTextField-root .MuiInputLabel-root,
.MuiTextField-root .MuiOutlinedInput-notchedOutline,
.MuiTextField-root .MuiOutlinedInput-notchedOutline legend span,
.MuiTextField-root {
	color: #fff;
	border-color: #fff;
}